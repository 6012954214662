import { Injectable } from '@angular/core';
import { SiteSettingsService as AbstractSiteSettingsService } from '@sitemule/core/abstract-services/site-settings.service';
import { BehaviorSubject } from 'rxjs';
import { appData } from 'src/data';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService extends AbstractSiteSettingsService {
  override currency$ = new BehaviorSubject('DKK');
  override locale$ = new BehaviorSubject(appData.language);
  override siteName$ = new BehaviorSubject('');
}
