import { Component, Inject } from '@angular/core';
import { SITE_SETTINGS_SERVICE_TOKEN } from '@sitemule/core/abstract-services/site-settings.service';
import { SEOService } from '@sitemule/utils/services/seo';
import { SiteSettingsService } from './services/siteSettings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'new-angular-site';

  constructor(@Inject(SITE_SETTINGS_SERVICE_TOKEN) siteSettings: SiteSettingsService, seoService: SEOService) {
    seoService.setPageLanguage(siteSettings.locale$.getValue());
  }
}
