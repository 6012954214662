import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import da from '@angular/common/locales/da';
import en from '@angular/common/locales/en';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SITE_SETTINGS_SERVICE_TOKEN } from '@sitemule/core/abstract-services/site-settings.service';
import { first } from 'rxjs';
import { appData } from 'src/data';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SiteSettingsService } from './services/siteSettings.service';

registerLocaleData(en);
registerLocaleData(da);

const translateLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, '/assets/i18n/', `.json?cb=${new Date().getTime()}`);
export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.log(`Missing translation for: ${params.key}`);
    return params.key;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHelper,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (translate: TranslateService) => () => {
        return new Promise<void>(res => {
          // Builder always maintain `en.json` so do not change the language here
          const availableLanguages = ['da', 'en'];
          const locale = availableLanguages.indexOf(appData.language) ? appData.language : availableLanguages[0];

          translate.addLangs(availableLanguages);
          translate.setDefaultLang(locale);

          translate
            .use(locale)
            .pipe(first())
            .subscribe(() => res());
        });
      },
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: SITE_SETTINGS_SERVICE_TOKEN,
      useClass: SiteSettingsService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
